// Use this to write your custom SCSS
.text-justify {
    text-align: justify;
  }
  .floating-wpp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    font-size: 14px;
    transition: bottom 0.2s;
    z-index: 15;
}

.floating-wpp .floating-wpp-button {
    position: relative;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
    transition: box-shadow 0.2s;
    cursor: pointer;
    overflow: hidden;
}

.floating-wpp .floating-wpp-button img,
.floating-wpp .floating-wpp-button svg {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
}



.floating-wpp:hover .floating-wpp-button {
    box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.4);
}

.floating-wpp .floating-wpp-popup {
    /*border: 2px solid white;*/
    border-radius: 6px;
    background-color: #E5DDD5;
    position: absolute;
    overflow: hidden;
    padding: 0;
    box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
    width: 0px;
    height: 0px;
    bottom: 0;
    opacity: 0;
    transition: bottom 0.1s ease-out, opacity 0.2s ease-out;
    transform-origin: bottom;
}

.floating-wpp .floating-wpp-popup.active {
    padding: 0 12px 12px 12px;
    width:max-content;
    height: auto;
    /*bottom: 82px;*/
    opacity: 1;
    right: 55px;
}

.floating-wpp .floating-wpp-popup .floating-wpp-message {
    background-color: white;
    padding: 8px;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s;
}

.floating-wpp .floating-wpp-popup.active .floating-wpp-message {
    opacity: 1;
    transition-delay: 0.2s;
}

.floating-wpp .floating-wpp-popup .floating-wpp-head {
    text-align: right;
    color: white;
    margin: 0 -15px 10px -15px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.floating-wpp .floating-wpp-input-message {
    background-color: white;
    margin: 10px -15px -15px -15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.floating-wpp .floating-wpp-input-message textarea {
    border: 1px solid #25d366;
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
    margin: 10px 0;
    width: 100%;
    max-width: 100%;
    font-family: inherit;
    font-size: inherit;
    resize: none;
}

.floating-wpp .floating-wpp-btn-send {
    margin-left: 12px;
    font-size: 0;
    cursor: pointer;
}
.d-contents{
    display:contents;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #eaeaea;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #aa2304;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  
  cursor: pointer;
}
.color-fiyat {
    font-weight: 900 !important;
    font-size: 40px !important;
    font-size: 4rem !important;
    line-height: 35px !important;
    line-height: 4rem !important;
    color: #ef6325 !important;
    -webkit-text-stroke: 2px #a23614 !important;
}
#one, #two{
    width:auto;
    border:1px solid red;
    margin:10px;
    padding:10px;
}
#wrapper{
    margin:0 10px;
    display:flex;
}